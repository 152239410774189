<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{$t(`message.latest_stats`)}}</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body v-if="chartDataReady">
      <chartjs-component-bar-chart
        :height="400"
        :data="chartData"
        :options="chartjsData.latestBarChart.options"
      />
    </b-card-body>
    <b-card-body v-else class="text-center">
      <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
    </b-card-body>
  </b-card>
</template>

<script>
import { cookie, token_id } from '@/products';
import {
  BSpinner, BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BSpinner,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      token_id,
      chartDataReady: false,
      chartjsData,
      rangePicker: [`${new Date().getFullYear()}-01-01`, `${new Date().getFullYear()}-12-12`],
      chartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            data: [],
            backgroundColor:'#28dac6',
            borderColor: 'transparent',
          },
        ],
      },
      cookie
    }
  },
  async created() {
    await this.fetchSum()
  },
  methods: {
    async fetchSum() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/sum_amount'
      };
      const data = {
          token : this.token_id,
          jwt: this.cookie
      }
      try {
          await fetch(url.prod, {
              method: 'POST',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=> {
              if (res === 'not_valid') {
                window.location.href = '/logout';
                return;
              }
              res.forEach(r=> {
                 this.chartData.datasets[0].data.push(r.total);
              })
          })
      } catch(err) {
          console.warn(err)
      }
      finally {
        this.chartDataReady=true
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
